<template lang="pug">
.menu.flex.align-center.justify-center
  .menu-icon.flex.align-center.justify-center(@click="toggleMenu")
    md-icon menu
  .pop-menu.overflow-hidden(v-if="showSideMenu")
    .overlay(@click="toggleMenu")
    .menu-content.flex.column.justify-stretch.overflow-overlay
      .user.flex
        //- <img src="https://picsum.photos/100/100?random=10">
        img(:src="userInfo.imgPath")
        .user-info.flex.column
          p {{userInfo.name}}
          p {{userInfo.account}}
          p(v-if="userInfo.userType === 1") 一般員工
          p(v-else) 管理者
      md-divider
      md-list.content 訂單管理
        md-list-item.item(@click="goPage('/newFishOrder')")
          md-icon set_meal
          span 新增釣魚單
        //- md-list-item.item(@click="goPage('/about')")
        //-   md-icon local_drink
        //-   span 新增商品單
        md-list-item.item(@click="goPage('/orderlist')")
          md-icon list_alt
          span 檢視訂單
        md-list-item.item(@click="goPage('/reserveOrderlist')")
          md-icon list_alt
          span 檢視保留訂單
      template(v-if="isAdmin")
        md-divider
        md-list.content 系統設定
          md-list-item.item(@click="goPage('/fish-setting')")
            md-icon grading
            span 釣魚訂單設定
          md-list-item.item(@click="goPage('/fish-buffer-setting')")
            md-icon grading
            span 釣魚緩衝設定
          //- md-list-item.item(@click="goPage('/about')")
          //-   md-icon schedule
          //-   span 釣魚提醒
          //- md-list-item.item(@click="goPage('/about')")
          //-   md-icon phishing
          //-   span 放魚設定
          //- md-list-item.item(@click="goPage('/about')")
          //-   md-icon storefront
          //-   span 商品管理
          //- md-list-item.item(@click="goPage('/about')")
          //-   md-icon print
          //-   span 出單機設定
          md-list-item.item(@click="goPage('/version-setting')")
            md-icon settings
            span 版本資訊
      md-divider

      md-button(class="md-dense log-out" @click="logout") 登出
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'pos-menu',
  data() {
    return {
      showSideMenu: false,
    };
  },
  computed: {
    ...mapGetters(['userInfo', 'isAdmin']),
  },
  methods: {
    ...mapMutations(['setUserInfo']),
    logout() {
      this.setUserInfo(undefined);
      localStorage.setItem('token', '');
    },
    goPage(path) {
      this.showSideMenu = false;
      this.$router.push(path);
    },
    toggleMenu() {
      this.showSideMenu = !this.showSideMenu;
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  width: 60px;
  height: 60px;
  z-index: 10;
}
.pop-menu {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.2);
    transition: opacity .3s ease-in-out;
  }
  .menu-content {
    position: absolute;
    background: white;
    top: 0;
    height: 100vh;
    width: 300px;
    transition: all .3s ease-in-out;
    padding: 0 40px;
    font-size: 16px;

    .user{
      margin-top: 12px;
      margin-bottom: 12px;
      img{
        border-radius: 40%;
        width: 80px;
        height: 80px;
      }
      .user-info{
        margin-left: 24px;
        justify-content: space-around;
      }
    }
    .content{
      width: 200px;
      font-size: 16px;
      span{
        font-size: 14px;
      }
    }
    .log-out{
      font-size: 16px;
    }
  }
}
</style>
