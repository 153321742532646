<template lang="pug">
.header.flex.align-center.justify-center
  pos-menu.menu-icon(v-if="showMenu")
  .title {{ headerTitle }}
</template>

<script>
import { mapGetters } from 'vuex';
import Menu from './Menu.vue';

export default {
  computed: {
    ...mapGetters(['showMenu', 'headerTitle']),
  },
  components: {
    'pos-menu': Menu,
  },
};
</script>

<style lang="scss" scoped>
.header {
  height: 60px;
  flex: 0 0 60px;
  background: #eee;
  position: relative;
  .menu-icon {
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>
