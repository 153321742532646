import Vue from 'vue';
import VueMaterial from 'vue-material';
import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';
import 'material-icons/iconfont/material-icons.css';
import Input from '@/components/base/Input.vue';
import Button from '@/components/base/Button.vue';
import Toast from 'vue-toastification';
import ZoomableImage from '@/components/base/ZoomableImg.vue';
import Plugins from './plugins';
import 'vue-toastification/dist/index.css';

import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
Vue.use(VueMaterial);
Vue.use(Plugins);
Vue.use(Toast, {
  transition: 'Vue-Toastification__fade',
  maxToasts: 5,
  newestOnTop: true,
});

Vue.component('dd-input', Input);
Vue.component('dd-button', Button);
Vue.component('zoomable-image', ZoomableImage);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
