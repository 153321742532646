import instance, { Organization } from './base';

export async function login(account, password) {
  const rsp = await instance.post(`/auth/${Organization}/login`, {
    account,
    password,
  });
  return rsp.data;
}

export async function getProfile() {
  const rsp = await instance.get(`/auth/${Organization}/profile`);
  return rsp.data;
}
