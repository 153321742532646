/* eslint-disable no-param-reassign */
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showMenu: true,
    headerTitle: 'Title',
    userInfo: undefined,

    continueOrder: undefined,
  },
  mutations: {
    setMenuButtonVisible(s, val) {
      s.showMenu = val;
    },
    setHeaderTitle(s, val) {
      s.headerTitle = val;
    },
    setUserInfo(s, info) {
      s.userInfo = info;
    },
    setContinueOrder(s, order) {
      s.continueOrder = order;
    },
  },
  actions: {
  },
  getters: {
    headerTitle: (s) => s.headerTitle,
    showMenu: (s) => s.showMenu,
    isLogin: (s) => s.userInfo !== undefined,
    userInfo: (s) => s.userInfo,
    isAdmin: (s) => s.userInfo?.status <= 1 || false,
    continueOrder: (s) => s.continueOrder,
  },
  modules: {
  },
});
