<template lang="pug">
#app.flex.column.stretch
  template(v-if="ready")
    pos-header(v-if="isLogin")
    login(v-else)
    //- pos-header
    router-view.page-view
  #loading(:class="{'show': showLoading}") {{ loadingMsg }}
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { getProfile } from '@/api/auth';
import Header from '@/components/Header.vue';
import LoginVue from '@/components/LoginForm.vue';

export default {
  components: {
    'pos-header': Header,
    login: LoginVue,
  },
  computed: {
    ...mapGetters(['isLogin']),
  },
  data() {
    return {
      ready: false,
      loadingMsg: 'Loading...',
      showLoading: false,
    };
  },
  methods: {
    ...mapMutations(['setUserInfo']),
    async testPrinter() {
      await this.init();
      await cordova.plugins.SummiPlugin.printText(
        'Testing\n', 30, true, true,
      );
    },
    async init() {
      await cordova.plugins.SummiPlugin.connectPrinter();
      console.log('init success');
    },
    async loadData() {
      await this.$execWithLoading(async () => {
        const rsp = await getProfile();
        console.log(rsp);
        this.setUserInfo(rsp?.data);
        this.$router.push('/orderlist');
      }, () => {
        this.setUserInfo(undefined);
        localStorage.setItem('token', '');
      });
      this.ready = true;
    },
  },
  mounted() {
    this.$root.$on('show-loading', (msg) => {
      this.showLoading = true;
      this.loadingMsg = msg || 'Loading...';
    });
    this.$root.$on('hide-loading', () => {
      this.showLoading = false;
      this.loadingMsg = 'Loading...';
    });
    this.loadData();
  },
};
</script>

<style lang="scss">
@import "./assets/helper.scss";

html {
  display: flex;
  flex-direction: row;
  width: 100vw;
  align-items: center;
  justify-content: center;
  background: #ccc;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
  color: #2c3e50;
  width: 100vw;
  height: 100vh;
  max-width: 360px;
  max-height: 648px;
  border: 1px solid #ccc;
  position: relative;
  font-size: 18px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.page-view {
  flex: 1;
}

#loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: white;
  z-index: 1000;
  opacity: 0.01;
  pointer-events: none;
  &.show {
    opacity: 1;
    pointer-events: default;
  }
}
</style>
