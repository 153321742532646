<template lang="pug">
.zoomable-img.flex.align-center.justify-center
  img(
    @click="showLarge"
    :width="width", :height="height", :src="thumb")
  .zoom-img.flex.column.align-center.justify-center.overflow-hidden(
    @click="closeLarge"
    v-if="showZoom")
    img(:src="src")
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
    thumb: {
      type: String,
      require: true,
    },
    src: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showZoom: false,
    };
  },
  methods: {
    showLarge(e) {
      if (this.src === '') {
        return;
      }
      e.preventDefault();
      e.stopPropagation();
      this.showZoom = true;
    },
    closeLarge(e) {
      e.preventDefault();
      e.stopPropagation();
      this.showZoom = false;
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  border-radius: 10%;
  padding: 4px;
}
.zoom-img {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  img {
    max-width: 90vw;
    max-height: 90vh;
  }
}
</style>
