import Vue from 'vue';
import VueRouter from 'vue-router';
// import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/fish-setting',
    name: 'FishSetting',
    component: () => import('../views/FishSetting.vue'),
  },
  {
    path: '/fish-buffer-setting',
    name: 'FishBufferSetting',
    component: () => import('../views/FishBufferSetting.vue'),
  },
  {
    path: '/version-setting',
    name: 'VersionSetting',
    component: () => import('../views/VersionSetting.vue'),
  },
  {
    path: '/orderlist',
    name: 'OrderList',
    component: () => import('../views/OrderList.vue'),
  },
  {
    path: '/reserveOrderlist',
    name: 'ReserveOrderList',
    component: () => import('../views/ReserveOrderList.vue'),
  },
  {
    path: '/newFishOrder',
    name: 'NewFishOrder',
    component: () => import('../views/NewFishOrder.vue'),
  },
  {
    path: '/order/:id',
    name: 'SingleOrder',
    component: () => import('../views/SingleOrder.vue'),
    props: true,
  },
  // {
  //   path: '*',
  //   redirect: '/',
  //   // component: Home,
  // },
];

const router = new VueRouter({
  routes,
});

export default router;
