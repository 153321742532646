import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://pos.bearathome.com.tw/api',
  // baseURL: '/api',
  // baseURL: 'http://localhost:8000',
  withCredentials: true,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use((request) => {
  const token = localStorage.getItem('token') || '';
  request.headers.set('Authorization', `Bearer ${token}`);
  try {
    if (request.headers['Content-Type'] === 'application/json') {
      if (request.data) {
        const data = JSON.parse(JSON.stringify(request.data));
        Object.keys(data).forEach((key) => {
          if (data[key] === '') {
            delete data[key];
          }
          if (data[key] === ' ') {
            data[key] = '';
          }
        });
        // eslint-disable-next-line no-param-reassign
        request.data = JSON.stringify(data);
      }
    }
  } catch (e) {
    console.log(e);
  }
  return request;
});

export const Organization = 'MTY1OTUxMTUyNDI5MDc5NzU2OA==';

export default instance;
