<template lang="pug">
.dd-button.flex.align-center.justify-center.clickable.pl-4.pr-4.pt-8.pb-8(
  :class="classList"
)
  slot
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'normal',
    },
    tabButton: {
      type: Boolean,
      default: false,
    },
  },
  name: 'dd-button',
  computed: {
    classList() {
      return {
        disabled: this.disabled,
        'no-border-top': this.tabButton,
        [this.type]: true,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/color.scss";

.dd-button {
  border: 1px solid #ccc;
  background: white;
  &:not(.disabled) {
    &:hover {
      filter: brightness(105%);
    }
    &:active {
      filter: brightness(95%);
    }
  }
  &.disabled {
    color: #ccc;
    cursor: not-allowed;
    background: #fefefe;
  }

  &.primary {
    background: $color-primary;
    color: white;
  }
  &.error {
    background: $color-error;
    color: white;
  }
  &.warning {
    background: $color-warn;
    color: white;
  }
  &.no-border-top {
    border-top: none;
  }
}
</style>
