/* eslint-disable no-param-reassign */
function paddingNumToTwo(num) {
  return num >= 10 ? num.toString() : `0${num}`;
}

function datetimeToString(date) {
  const time = new Date(date);

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(time.getTime())) {
    return '';
  }

  const month = paddingNumToTwo(time.getMonth() + 1);
  const day = paddingNumToTwo(time.getDate());
  const hour = paddingNumToTwo(time.getHours());
  const min = paddingNumToTwo(time.getMinutes());
  const sec = paddingNumToTwo(time.getSeconds());

  return `${time.getFullYear()}/${month}/${day} ${hour}:${min}:${sec}`;
}

function timeToString(date) {
  const time = new Date(date);

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(time.getTime())) {
    return '';
  }

  const hour = paddingNumToTwo(time.getHours());
  const min = paddingNumToTwo(time.getMinutes());

  return `${hour}:${min}`;
}

function dateToString(date) {
  const time = new Date(date);

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(time.getTime())) {
    return '';
  }

  const month = paddingNumToTwo(time.getMonth() + 1);
  const day = paddingNumToTwo(time.getDate());
  return `${time.getFullYear()}/${month}/${day}`;
}

function copyToClipboard(text) {
  const input = document.createElement('input');
  input.value = text;
  const body = document.querySelector('body');
  body.append(input);

  input.select();
  document.execCommand('copy');
  input.remove();
}

function getParameterByName(name, url) {
  let useURL = url;
  if (!useURL) {
    useURL = window.location.href;
  }
  const useName = name.replace(/[[]]/g, '\\$&');
  const regex = new RegExp(`[?&]${useName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(useURL);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export default {
  install(Vue) {
    Vue.prototype.$datetimeToString = datetimeToString;
    Vue.prototype.$dateToString = dateToString;
    Vue.prototype.$timeToString = timeToString;
    Vue.prototype.$copyToClipboard = copyToClipboard;
    Vue.prototype.$getParameterByName = getParameterByName;
    Vue.prototype.$showSuccess = function (msg) {
      this.$toast.success(msg, {
        position: 'top-center',
        timeout: 2000,
        transition: 'fade',
      });
    };
    Vue.prototype.$showError = function (msg) {
      this.$toast.error(msg, {
        position: 'top-center',
        timeout: 2000,
        transition: 'fade',
      });
    };
    Vue.prototype.$showLoading = function (msg) {
      this.$root.$emit('show-loading', msg);
    };
    Vue.prototype.$hideLoading = function () {
      this.$root.$emit('hide-loading');
    };

    Vue.prototype.$execWithLoading = async function (func, errFunc) {
      return this.$execWithLoadingMessage('Loading', func, errFunc);
    };
    Vue.prototype.$execWithLoadingMessage = async function (msg, func, errFunc) {
      this.$showLoading(msg);
      let promise = new Promise((r) => {
        r();
      });

      return new Promise((r) => {
        setTimeout(async () => {
          promise = promise.then(() => func.apply(this));
          try {
            await promise;
          } catch (e) {
            console.log(`loading exception: ${e}`);
            if (errFunc !== undefined) {
              try {
                errFunc(e);
              } catch (e2) {
                console.log(e2);
              }
            }
          }
          this.$hideLoading();
          r();
        }, 10);
      });
    };
  },
};
