<template lang="pug">
.login-form.flex.align-center.justify-center
  .form.p-16.flex.column.justify-space-between.gap-32
    .title 東龍海釣場
    .inputs.flex.column.gap-16
      .col 帳號
      dd-input(v-model="account")
      .col 密碼
      dd-input(v-model="password", type="password")
    .buttons.flex.column
      dd-button(
        @click.native="login"
        :disabled="loading"
        type="primary") 登入
</template>

<script>
import { mapMutations } from 'vuex';
import { login } from '@/api/auth';

export default {
  data() {
    return {
      account: '',
      password: '',

      loading: false,
    };
  },
  methods: {
    ...mapMutations(['setUserInfo']),
    async login() {
      this.loading = true;
      await this.$execWithLoading(async () => {
        const data = await login(this.account, this.password);
        const token = data?.data?.token;
        const user = data?.data?.user;
        this.setUserInfo(user);
        localStorage.setItem('token', token);
        this.$router.push('/orderlist');
      }, (e) => {
        this.$toast.error('登入失敗', {
          position: 'top-center',
          timeout: 2000,
          type: 'error',
          transition: 'fade',
        });
        console.log(e);
      });
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.login-form {
  background: white;
  background-image: url('/login-bg.jpeg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  font-size: 16px;
  .form {
    background: rgba(255, 255, 255, 0.9);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    // border: 2px solid #ccc;
    width: calc(100% - 40px);
    .title {
      font-size: 24px;
      text-align: center;
    }
  }
}
</style>
