<template lang="pug">
input.dd-input(
  @input="input",
  v-model="innerValue"
  :type="type")
</template>

<script>
export default {
  name: 'dd-input',
  props: {
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  data() {
    return {
      innerValue: '',
    };
  },
  methods: {
    input() {
      this.$emit('input', this.innerValue);
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  padding: 8px;
  font-size: 1rem;
}
</style>
